

.recipe_header{
    height: 8%;
}

@media(orientation: landscape){
    @media (max-height: 600px) {
      .recipe_header{
          height: 12%;
      }
    }
    @media (max-height: 400px) {
      .recipe_header{
          height: 15%;
      }
    }
}

.recipe_content{
    --background: transparent !important;
    scrollbar-width: thin;
    scrollbar-color: #196b69 #f5f5f5;
}

.recipe_background{
    background-image: linear-gradient(45deg, #93cfbd 0%, #e4efe9 100%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(3px);
    z-index: -1;   
}

.recipe_background_img{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../resources/bg1.png');
    background-size: cover;
    filter: opacity(10%);
    z-index: -1;
}

/* Container */
.recipe_container{
    width: 100%;
    margin: auto;
    overflow: hidden;
    padding: 0px;
}

.recipe_problem_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80vh !important;
}

.recipe_problem_btn{
    margin-right: auto;
    margin-left: auto;
    width: fit-content;
    --background: #28cc9e !important;
    background-color: #28cc9e !important;
    border-radius: 5px;
}

.recipe_problem_label{
    display: flex;
    justify-content: center;
    /* vertical-align: middle; */
    /* align-items: center; */
    text-align: center;
    /* margin-top: 5%; */
    color: #196b69;
    font-size: 1.5em;
    
}

/* RECIPE INTRO */
.recipe_intro{
    display: flex;
    flex-direction: row;
    min-height: 80vh;
    width: 100vw;
    padding-left: 10vw;
    padding-bottom: 10vh;
    border-radius: 0 0 30px 30px;
    box-shadow: -1px 2px 12px 1px rgba(0,0,0,0.39);
}
@media only screen and (max-width: 800px) {
    .recipe_intro{
        flex-direction: column;
        width: 100%;
        padding: 0;
    }
}




/*   Top Left Image and 3 card infos   */
.recipe_img_div{
    padding: 2vh 2vw;
    width: 30vw;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.recipe_image{
    width: 100%;
    height: auto;
    margin-bottom: 5vh;
}
.recipe_info_card{
    color: white;
    display: flex;
    justify-content: center;
    width: 80%;
    height: 5vh;
    padding: 5px;
    margin: 5px;
    border-radius: 20px;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    align-self: center;
    background: rgba(17, 75, 74, 0.7);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.1px);
    -webkit-backdrop-filter: blur(5.1px);
    border: 1px solid rgba(17, 75, 74, 0.22);
}
.recipe_info_title{
    display: flex;
    justify-content: center;
    align-content: center;
    vertical-align: middle;
}
.recipe_info_card_icon{
    margin: auto;
    vertical-align: middle;
    color: white;
    padding-right: 10px;
    font-size: large;
}
.recipe_info_card_value{
    color: white;
    padding-left: 10px;
    font-size: large;
    margin-left: auto;
    margin-right: 5px;
    /* align-self: flex-end; */
}

@media only screen and (max-width: 800px) {
    .recipe_image{
        width: 80%;
        margin: auto;
        margin-bottom: 2vh;
    }
    .recipe_img_div{
        width: 100%;
        height: 50%;
        margin-bottom: 5%;
    }
}
/*   End  */








/*   Top Right Information of the recipe  */
.recipe_desc_title{
    color: black;
    font-size: xx-large;
    font-weight: 100;
    font-style: italic;
    margin-bottom: 2vh;
    font-size: 40px;
    font-family: serif;
}
.recipe_desc_div{
    display: flex;
    padding: 20vh 5vw 5vh 5vw;
    flex: 1;
    flex-direction: column;
}
.recipe_desc_star{
    width:40px;
    height: auto;
    padding-left: 10px
}
.recipe_desc_subtitle{
    color: black;
    font-weight: 50;
}
.recipe_desc_category{
    display: inline-block;
    justify-content: center;
    align-items: center;
    align-content: center;
    vertical-align: middle;
    color: black;
    font-weight: 50;
    margin-bottom: 5vh;
}
.recipe_desc_description{
    color: black;
    font-weight: 200;
    padding: 20px 20px 20px 5px;
    font-size: 20px;
}
.recipe_desc_country{
    color: #196b6a;
    font-weight: bold;
}

@media only screen and (max-width: 800px) {
    .recipe_desc_div{
        width: 100%;
        flex: 0;
    }
    .recipe_desc_div{
        padding-top: 0;
    }
}
/*   End  */







/*   Ingredients  */
.recipe_ing_step_container{
    display: flex;
    gap: 0px;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px 15px;
    border-radius: 10px;
    margin-top: 2vh;
}
.recipe_ingredients{
    box-shadow: -1px 2px 6px 1px rgba(0,0,0,0.39);
    background-color: #93cfbd;
    display: flex;
    flex-direction: column;
    flex: 1; 
    min-width: 300px; 
    padding: 20px;
    border-radius: 20px;
}
.recipe_ing_title{
    text-align: center;
    color: black;
    display: inline-block;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: italic;
    font-size: 3em;
}
.recipe_ing_list{
    margin-top: 5vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    border-radius: 10px;
    color: rgb(25,107,105);
    font-weight: 700;
    font-size: 1.4em;
}
.recipe_ing{
    margin: 0.7em;
}

@media only screen and (max-width: 800px) {
    .recipe_ingredients{
        padding-left: 0;
        /* margin-left: 2%; */
    }
    .recipe_ing{
        font-size: 1.1rem;
    }
    .recipe_ing_list{
        width: fit-content;
        margin-top: 4%;
    }
    .recipe_ing_title{
        font-size: 2rem;
    }
}
/*   End  */







/*   Steps  */
.recipe_steps{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 300px;
    padding: 20px;
    background-color: #93cfbd;
    border-radius: 20px;
    box-shadow: -1px 2px 6px 1px rgba(0,0,0,0.39);
}
.recipe_steps_title{
    display: inline-block;
    font-weight: 100;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: italic;
    font-size: 3em;
    color: black;
    text-align: center;
}
.recipe_steps_list{
    margin-top: 5vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    color: rgb(25,107,105);
    font-weight: 700;
    font-size: 1.4em;
    padding-left: 1%;
}
.recipe_step{
    margin: 1em 0.4em;
}

@media only screen and (max-width: 800px) {
    .recipe_steps{
        padding-left: 0;
        /* margin-left: 2%; */
    }
    .recipe_steps_list{
        margin-top: 4%;
        width: 100%;
        font-size: 1.1rem;
    }
    .recipe_steps_title{
        font-size: 2rem;
    }
}
/*   End  */








/* Gallery */
.gallery_container{
    box-shadow: -1px 2px 6px 1px rgba(0,0,0,0.39);
    background-color: #93cfbd;
    margin: auto;
    margin-top: 10px;
    padding: 0 10px;
    max-width: 80%;
    width: fit-content;
}
.inner_gallery_container{
    overflow-x: auto;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row; 
    flex-shrink: 0;
    flex-wrap: nowrap; 
    white-space: nowrap;  
    overflow-y: hidden;
    justify-content: start !important;
    align-items: start !important;
}
.gallery_item{
    width: 175px; 
    height: 175px;
    margin: 5px; 
    background-color: grey;
    display: flex;
    justify-content: center; 
    align-items: center;
    flex-shrink: 0; 
    border-radius: 5px;
    overflow: hidden;
}
.gallery_img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.gallery_img:hover{
    transform: scale(1.1);
}

/* Responsive styling */
@media (max-width: 768px) {
    .gallery_item {
        width: 48%; /* Two items per row */
        max-width: none; /* Remove max-width */
    }
    .gallery_container{
        max-width: 95%;
    }
}

@media (max-width: 480px) {
    .gallery_item {
        width: 50%; /* One item per row */
        max-width: none; /* Remove max-width */
    }
    .gallery_container{
        max-width: 95%;
    }
}
/* End */





/* Notebook Responsive */
@media (max-width: 768px) {
    .recipe_ing_step_container {
      flex-direction: column; /* Stack items vertically */
      gap: 10px; /* Reduce the gap between items */
    }
}

@media (min-width: 769px){
.recipe_ing_step_container {
    position: relative;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.218); 
    border-radius: 10px;
    background-color: #196b6aa4;
}

.recipe_ingredients, .recipe_steps{
    flex: 1;
    padding: 20px;
    border: 1px solid #accfc4; 
    border-radius: 5px;
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
    overflow: auto;
    position: relative;
}
.recipe_ing_step_container::before {
    content: '';
    position: absolute;
    top: 15px; 
    bottom: 15px; 
    left: 50%;
    width: 3px;
    background-color: #8d8175;
    box-shadow: 0px 0px 10px #2e2a27;
    transform: translateX(-50%);
    z-index: 1; 
}
.recipe_ingredients::before, .recipe_steps::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 15px;
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.05),
        rgba(0, 0, 0, 0)
    );
    z-index: 1;
} 
.recipe_ingredients::before {
    left: 0;
}
.recipe_steps::before {
    right: 0;
}
}


.recipe_no_recipe{
    margin: auto;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    text-align: center;
    /* margin-top: 5%; */
    color: #196b69;
    font-size: 1.5em;
    height: 80vh !important;
}




.recipe_alert .alert-wrapper {
    background-color: #196b69;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.268);
  }
  .recipe_alert .alert-wrapper .alert-message {
    color: white;
  }
  .recipe_alert_arabic .alert-wrapper .alert-message {
    direction: rtl !important;
  }
  .recipe_alert .alert-wrapper .alert-head { 
    background: #114b4a;
    margin-bottom: 15px
  }
  
  .recipe_alert .alert-wrapper .alert-head .alert-title{
    color: rgb(255, 255, 255) !important;
  }
  
  ion-backdrop{
    opacity: 0.75 !important;
    background-color: rgb(0, 0, 0);  /* Optional: Semi-transparent background */
  }
  
  .recipe_alert .alert-button {
    border-radius: 10px;
    min-width: 40%;
    width: fit-content;
    /* text-align: center; */
    margin: auto;
    background-color: #28cc9e;
    color: white;
    
  }
  
  .recipe_alert .alert-button-inner{
    /* color: red;
    background-color: red; */
    text-align: center !important;
    justify-content: center !important;
  }

  .recipe_loading{

  }
  .spinner{
    /* margin-top: auto; */
    /* margin-bottom: auto; */
    padding-top: 10px;
    margin-left: 5px;
    --color: #196b69;
  }
  
