.navbar_header{
    --background: rgb(25,107,105);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    --color: white;
}

.navbar_btns{
    width: 100%;
    height: 100%;
    display: flex;  
    vertical-align: middle;
}

.navbar_btn{
    --color: white;
    font-family: "Calibri", sans-serif;
    font-size: calc(10px + 0.5vw);
    text-transform: none;
    /* margin-right: 20vh; */
    padding-right: 2vw;
}

.logo_img{
    display: block;
    width: auto !important;
    height: 80% !important;
    margin-right: 2%;
    filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.3));
}

@media(orientation: portrait){
    @media (max-width: 600px) {
      .logo_img{
        width: auto !important;
        height: 50% !important;
      }
      .navbar_btn{
        font-size: calc(9px + 0.2vw);
        padding:0px;
      }
    
    }
}


.navbar_pop_content{
  --box-shadow: none;
  --background-color: #196b69 !important;
  height: fit-content;
}

.navbar_list{
  height: fit-content;
  background-color: #196b69;
}

.navbar_item{
  --background: #196b69;
  --border: none !important;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);s */
}

.navbar_item_arabic{
  direction: rtl !important;
}

.navbar_modal{
  background-color: #0000007f;
}

.navbar_modal_toolbar{
  --background: #114b4a ;
}

.navbar_modal_content{
  height: fit-content;
  --background: #196b69 !important;
}

.navbar_modal_list{
  background-color: #196b69;
}

.navbar_modal_item_left{
  --background: #196b69;
}

.navbar_modal_item_right{
  --background: #114b4a;
}

.navbar_modal_button{
  --background: #28cc9e;
  margin: auto;
}

.navbar_modal_row_btn{
  margin-top: 15%;
  display: flex;
  justify-content: center;
}

.navbar_modal_col_btn{
  display: flex;
  justify-content: center;
}

.item_arabic{
  direction: rtl;
}












.navbar_alert .alert-wrapper {
  background-color: #196b69;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.268);
}
.navbar_alert .alert-wrapper .alert-message {
  color: white;
}
.navbar_alert_arabic .alert-wrapper .alert-message {
  direction: rtl !important;
}
.navbar_alert .alert-wrapper .alert-head { 
  background: #114b4a;
  margin-bottom: 15px
}

ion-backdrop{
  opacity: 0.75 !important;
  background-color: rgb(0, 0, 0);  /* Optional: Semi-transparent background */
}

.navbar_alert .alert-button {
  border-radius: 10px;
  display: flex !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40%;
  /* text-align: center; */
  margin: auto;
  background-color: #28cc9e;
  color: white;
  
}

.navbar_alert .alert-button-inner{
  /* color: red;
  background-color: red; */
  text-align: center !important;
  justify-content: center !important;
}

.navbar_small{
  display: none;
}

@media (max-width: 768px) {
  .navbar_small{
    display: block;
  }
  .navbar_big{
    display: none;
  }
}