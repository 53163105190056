.form_header{
    height: 8%;
}

@media(orientation: landscape){
    @media (max-height: 600px) {
      .form_header{
          height: 12%;
      }
    }
    @media (max-height: 400px) {
      .form_header{
          height: 15%;
      }
    }
}

.form_content{
    --background: transparent !important;
    scrollbar-width: thin;
    scrollbar-color: #196b69 #f5f5f5;
}

.form_background{
    background-image: linear-gradient(45deg, #93cfbd 0%, #e4efe9 100%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(3px);
    z-index: -1;   
}

.form_background_img{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../resources/bg1.png');
    background-size: cover;
    filter: opacity(30%);
    z-index: -1;
}

.form{
    border-radius: 20px;
    background-color: #196b69;
    flex: 1;
    margin: auto;
    padding: 0;
    background: rgba(25,107,105,.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0px;
    margin-bottom: 30px;
}

.form_main{
    width: 90%;
    max-width: 400px;
    max-height: 750px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 5vh;
}

@media screen and (max-width: 899px) and (orientation: landscape) {
    .form{
        max-width: 400px;
        max-height: 600px;
    }
    .form_main{
        width: 80%;
        height: auto;  
        max-width: 500px; 
        max-height: none;     
    }
}

.form_logo{
    width: 50%;
    height: auto;
    margin-top: 15%;
    margin-bottom: 10%;
}

:root .form_input{
    width: 90%;
    margin-top: 2vh;
    margin-bottom: 2vh;
    --placeholder-color: rgb(255, 255, 255) !important;
    --highlight-color: rgb(239, 237, 237) !important;

    /* --color-focused: rgb(83, 0, 128); */
    
    --border-color: rgb(40,204,158) !important;

}

.form_btn{
    width: 50%;
    margin-top: auto;
    margin-bottom: 12%;
    --background: #28cc9e !important;
    background-color: #28cc9e !important;
    border-radius: 5px;
}

.form_spinner{
    font-size: medium;
    margin: auto;
    --color: #ffffff;
}

.form_tags{
    margin-bottom: 8%;
    width: 100%;
    color: white !important;
}

.form_tag_left{
    display: inline-block;
    /* background-color: red; */
    width: 50%;
    /* margin-right: 20px; */
    margin-bottom: 5vh;
    text-align: center;
    color: white !important;
}

.form_tag_right{
    display: inline-block;
    width: 50%;
    /* margin-left: 20px; */
    margin-bottom: 5vh;
    text-align: center;
    color: white !important;
}

.form_tag{
    color: white;
}

.form_label{
    width: 70%;
    margin: 5% auto;
}

.form_main_signup{
    margin-bottom: 10vh;
}

.signup_tags{
    margin-bottom: 8vh;
}

.signup_tag{
    margin: auto;
}

.signup_btn{
    margin-bottom: 4%;
    margin-top: 4%;
}

.reset_tags{
    margin-bottom: 8vh;
}

.reset_tag{
    margin: auto;
}

.form_verify{
    height: 100%;
    display: flex;
}

.verify_btn{
    margin: auto;
    height: fit-content;
    font-size: larger;
    --background: #28cc9e !important;
    background-color: #28cc9e !important;
    border-radius: 5px;
}

.form_alert .alert-wrapper {
    max-width: 20%;
    background-color: #196b69;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.268);
}

.form_alert .alert-wrapper .alert-message {
    color: white;
}

.form_alert_arabic .alert-wrapper .alert-message {
    direction: rtl;
    font-family:adobe arabic;
    text-align: right;
}

.form_alert .alert-wrapper .alert-head { 
    background: #114b4a;
    margin-bottom: 15px;
    color: white;
}

.form_alert .alert-wrapper .alert-head .alert-title{ 
    color: white;
}
  
ion-backdrop{
    opacity: 0.75 !important;
    background-color: rgb(0, 0, 0);  /* Optional: Semi-transparent background */
}
  
.form_alert .alert-button {
    border-radius: 10px;
    min-width: 40%;
    width: fit-content;
    /* text-align: center; */
    margin: auto;
    background-color: #28cc9e;
    color: white; 
}
  
.form_alert .alert-button-inner{
    text-align: center !important;
    justify-content: center !important;
}
